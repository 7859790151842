import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import StorageProviders from "./_storageProviders";
const Page = () => {
  const keyRef = 'leviia';
  const provider = 'Leviia';

  return (
    <Layout.Layout textColor="dark">
      <Layout.SEO
        title ={`Automated ${provider} backup & migration`}
        description={`Schedule ${provider} backup & migration to any storage. Supports any S3 compliant storage and Dropbox, Google Drive, SFTP and more.`}
      />
      <Layout.Hero
        type="primary"
        title={(<>Automated <span className="text-primary">{provider} Storage</span> backup</>)}
        subTitle={(
          <>SimpleBackups makes automating your Leviia storage backups easy, ensuring that your data is always safe and secure.<br/>
            With intuitive interface, you can set up automated backups in just a few clicks.</>)}
        checkMarks={[`Migrate to ${provider}`, `Backup your ${provider}`]}
        icons={[
          <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
          <Ui.Image filename={`brands/icons/${keyRef}-ico.png`} alt={`${provider} backup`}  className="w-6"/>,
        ]}
        cta={(<Ui.SignupButton className="mt-6" noSubtitle text={`Automate your ${provider} Backup`} urlAtrr={{ sb_source: "website", sb_term: `${keyRef}-hero` }} />)}
        illustration={(
          <Ui.Image className="" filename="simplebackups-leviia-backup-hero.png" alt="SimpleBackups Leviia Storage backup"/>
        )}
        withIllustrationBox={false}
      />

      <Ui.Container>
        <Sections.JoinBrandsSection />
      </Ui.Container>

      <Ui.Section>
        <Ui.Container>
          <Ui.Row>
            <Ui.FeatureRows items={
              [
                {
                  preTitle: "Control your backups",
                  title: (<>Automate your <span className="text-primary">{provider} backup</span></>),
                  description: (
                    <>
                      <p>
                        Enable automatic Leviia storage backups to multiple storage providers and SFTP drives, S3, and even your personal server.
                        <div className="my-6">
                          <p className="mb-3 font-light text-xs tracking-wider uppercase opacity-75">Back it up to any storage provider</p>
                          <Sections.StorageProvidersList subject={provider} />
                        </div>
                        <Ui.FeatureChecks items={['Or use our SimpleStorage provider']} />
                        <Ui.Link arrow="right" to="/catalog/">Discover all integrations</Ui.Link>
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-leviia-storage-runs.png'
                    alt="Leviia Storage Backup Configuration"
                    style={{maxWidth: 450}}
                  />)
                },
                {
                  preTitle: "Migration in a click",
                  title: (<>How to migrate your data to <span className="text-primary">{provider}?</span></>),
                  description: (
                    <>
                      <p>
                        Forget the hassle of manually migrating your data to Leviia.<br/>
                        SimpleBackups do the work for you with our automated process, saving you time and effort.<br/>
                        Trust us to handle your data with the utmost care and security as we transfer it seamlessly to its destination.
                        <br/>
                        <Ui.FeatureChecks items={['Agent-free setup', 'Migrate from any provider']} />
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-leviia-storage-migration.png'
                    alt="Migrate data to Leviia Storage"
                    style={{maxWidth: 450}}
                  />)
                },

                {
                  preTitle: "Trust your backups",
                  title: (<>Protect your Leviia data with the <span className="text-primary">highest security standard</span></>),
                  description: (
                    <>
                      <p>
                        We prioritize security and privacy!<br/>
                        Take advantage of our fully GDPR-compliant solution to make sure your data is migrated safely between {provider} and other storage providers.<br/>
                        We apply the same, strict European data safety standards, regardless of your location.
                        <Ui.FeatureChecks items={['Team & User Authorization', 'Multiple-Factor Auth', 'ISO-27001 certified', 'GDPR Compliancy', 'Backup Encryption', 'SSL & self-hosted keys']} />
                        <Ui.Link arrow="right" to="/security-first/">How we treat your data</Ui.Link>
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className=""
                    filename='simplebackups-default-cloud-storage-security.png'
                    alt="Backup security and privacy"

                    style={{maxWidth: 550}}
                  />)
                },

              ]}></Ui.FeatureRows>

          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt" id="features">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
            <div className="text-center">
              <p className="mb-6">SimpleBackups is fully integrated with {provider}, meaning all is configurable from an easy-to-use UI.</p>
              <p className="py-5 text-lg font-bold">Automate your {provider} backups and migration!</p>
              <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
                <li><i className="far fa-check text-green mr-2"></i>Incremental Backup</li>
                <li><i className="far fa-check text-green mr-2"></i>Fail-over detection</li>
                <li><i className="far fa-check text-green mr-2"></i>Secured transfer</li>
              </ul>

              <div className="mt-16 mb-8">
                <Ui.Typography tag="h3" className="text-2xl text-center ">Wait... there's even more...</Ui.Typography>
              </div>
              <Sections.FeatureSlider tags={["snapshot"]} />
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt" id="features">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center">Effortless migration between storage providers</Ui.Typography>
            <p className="mb-6 text-center">
              Data migration to Leviia with SimpleBackups is – you guessed it – simple!<br/>
              Connect your SimpleBackups account with your old storage provider, select your bucket, destination storage – and done!<br/>
              And hey – did we mention that it works both ways?<br/>SimpleBackups can back up your data to any storage provider you choose.
            </p>
            <div className="mt-16 text-center">
              <StorageProviders></StorageProviders>
              <div className="mt-16"><Ui.Link arrow="right" to="/catalog/">Discover our Integration catalog</Ui.Link></div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Sections.SectionLearn
        title={<>Wanna learn more about {provider} backup?</>}
        text={`Find out more and get started with our ${provider}  Backup guides`}
        tags={['storage-replication']}
      />

      <Sections.SectionGetStarted/>
      <Sections.SectionTestimonialExtended />
      <Sections.SectionFaq color="alt" faqs={[
        'leviia_migrate_from_aws',
        'leviia_migrate_to_provider',
        'is_secure',]}
      />

    </Layout.Layout>
  )
}

export default Page
